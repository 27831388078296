<template>
	<chart-tool
		:chart-selected.sync="displayCharts"
		:chart-options="SORTATION_CHART"
		:no-of-row-selected.sync="numOfChartsPerRowSelected"
		:no-of-row-options="NUM_OF_CHART_OPTIONS"
	/>
</template>

<script>
	import store from "@/store";
	import ChartTool from "@common-components/chart-tool/ChartTool.vue";
	import { computed } from "@vue/composition-api";
	import { SORTATION_CHART, NUM_OF_CHART_OPTIONS } from "@configs/charts";

	export default {
		components: {
			ChartTool,
		},
		setup() {
			const displayCharts = computed({
				get: () => store.state.sortation.tool.displayCharts,
				set: (val) => {
					store.commit("sortation/UPDATE_DISPLAY_CHARTS", val);
				},
			});

			const numOfChartsPerRowSelected = computed({
				get: () => store.state.sortation.tool.numOfChartsPerRow,
				set: (val) => {
					store.commit("sortation/UPDATE_NUM_OF_CHARTS_PER_ROW", val);
				},
			});

			return {
				SORTATION_CHART,
				displayCharts,

				numOfChartsPerRowSelected,
				NUM_OF_CHART_OPTIONS,
			};
		},
	};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>