<template>
	<fix-top-container>
		<template v-slot:header>
			<div class="container-fluid">
				<div class="row">
					<div class="col-xl-8">
						<sortation-filter />
					</div>

					<div class="col-xl-4">
						<sortation-tool />
					</div>
				</div>
			</div>

			<div class="container-fluid">
				<div class="row">
					<div class="col-xl-4 col-xxl-2">
						<data-card
							:background-color="Colors.Amaranth"
							title="GP Margin"
							:type="DataCardTypes.Percentage"
							:value="26"
						/>
					</div>
					<div class="col-xl-4 col-xxl-2">
						<data-card
							:background-color="Colors.Orange"
							title="EBIT Margin"
							:type="DataCardTypes.Percentage"
							:value="5"
						/>
					</div>
					<div class="col-xl-4 col-xxl-2">
						<data-card
							:background-color="Colors.AndroidGreen"
							title="FTE"
							:type="DataCardTypes.Number"
							:value="245"
						/>
					</div>
					<div class="col-xl-4 col-xxl-2">
						<data-card
							:background-color="Colors.Azure"
							title="OPEX Ratio"
							:type="DataCardTypes.Percentage"
							:value="25"
						/>
					</div>
					<div class="col-xl-4 col-xxl-2">
						<data-card
							:background-color="Colors.Teal"
							title="REV per FTE"
							:type="DataCardTypes.Dollar"
							:value="829394"
						/>
					</div>
					<div class="col-xl-4 col-xxl-2">
						<data-card
							:background-color="Colors.Navy"
							title="GP per FTE"
							:type="DataCardTypes.Dollar"
							:value="216038"
						/>
					</div>
				</div>
			</div>
		</template>

		<template v-slot:body>
			<div class="container-fluid">
				<div class="row">
					<div :class="chartClass" v-for="chart in displayCharts" :key="chart.name">
						<component :is="chart.value" />
					</div>
				</div>
			</div>
		</template>
	</fix-top-container>
</template>

<script>
	import store from '@/store';
	import { computed } from '@vue/composition-api';

	import FixTopContainer from "@common-components/fix-top-container/FixTopContainer.vue";
	import DataCard from "@common-components/data-card/DataCard.vue";
	import DataCardTypes from "@common-components/data-card/data-card-types";
	import Colors from "@configs/colors";

	import SortationFilter from "./components/SortationFilter.vue";
	import SortationTool from "./components/SortationTool.vue";
	import Revenue from "./components/Revenue.vue";

	export default {
		components: {
			SortationFilter,
			SortationTool,

			DataCard,
			Revenue,
			'aaa': Revenue,
			'bbb': Revenue,
			FixTopContainer,
		},
		setup() {
			const chartClass = computed(() => {
				const result = 12 / store.state.sortation.tool.numOfChartsPerRow;
				return 'col-md-' + result;
			})

			const displayCharts = computed(() => store.state.sortation.tool.displayCharts);

			return {
				DataCardTypes,
				Colors,
				
				chartClass,
				displayCharts,
			};
		},
	};
</script>

<style language="css" scoped>
</style>