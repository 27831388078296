<template>
	<div class="card">
		<div class="card-body">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-4">
						<b-form-group label="TODO">
							<b-form-select
								placeholder="Select region"
							/>
						</b-form-group>
					</div>

					<div class="col-md-4">
						<b-form-group label="TODO">
							<b-form-select
								placeholder="Select region"
							/>
						</b-form-group>
					</div>

					<div class="col-md-4">
						<b-form-group label="TODO">
							<b-form-select
								placeholder="Select region"
							/>
						</b-form-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, ref } from '@vue/composition-api';
	import { BFormGroup, BFormSelect } from "bootstrap-vue";

	export default {
		components: {
			BFormGroup,
			BFormSelect,
		},
		// setup() {
		// 	const regionSelected = ref(null);
		// 	const regionOptions = computed(() => [
		// 		{ value: "HK", text: "Hong Kong" }
		// 	]);

		// 	const groupSelected = ref(null);
		// 	const groupOptions = computed(() => [
		// 		{ value: "KEC", text: "KEC Group" }
		// 	]);

		// 	const groupSelected = ref(null);
		// 	const groupOptions = computed(() => [
		// 		{ value: "KEC", text: "KEC Group" }
		// 	]);

		// 	return {
		// 		groupSelected,
		// 		groupOptions
		// 	}
		// },
	};
</script>

<style scoped>
</style>