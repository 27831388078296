<template>
	<multi-select
		v-model="computeSelected"
		:options="options"
		:multiple="true"
		:close-on-select="false"
		:clear-on-select="false"
		:preserve-search="true"
		:searchable="false"
		selectLabel="Select?"
		deselectLabel="Remove?"
		label="name"
		track-by="name"
		:preselect-first="true"
	>
		<template slot="tag">{{ "" }}</template>
		<template slot="selection" slot-scope="{ values }">
			<span class="multiselect__single" v-if="values.length">
				{{ values.length }} selected
			</span>
		</template>
	</multi-select>
</template>

<script>
	import MultiSelect from "vue-multiselect";
    import { computed } from '@vue/composition-api';

	export default {
		props: {
			id: {
				type: String,
				required: false
			},
			selected: {
				type: Array,
				required: true,
			},
			options: {
				type: Array,
				required: true,
			},
		},
		components: {
			MultiSelect,
		},
        setup(props, ctx) {
            const computeSelected = computed({
                get: () => props.selected,
                set: (val) => {
                    ctx.emit("update:selected", val);
                }
            });

            return {
                computeSelected
            }
        }
	};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect {
    min-height: 0px !important;
}
.multiselect__tags {
    min-height: 0px !important;
    border: 1px solid #d8d6de !important;
}
.multiselect__single {
    font-size: inherit !important;
}
</style>