<template>
	<div class="fix-container">
		<slot name="header"/>

		<div class="inner-scroll">
			<slot name="body"/>
		</div>
	</div>
</template>

<script>
	export default {
	};
</script>

<style language="css" scoped>
	.fix-container {
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}
	.inner-scroll {
        display: flex;
        height: 100%;
		overflow-y: auto;
	}
</style>