<template>
	<div class="card">
		<div class="card-body">
			<div class="container-fluid">
				<div class="row">
					<div class="col-xl-7">
						<b-form-group label="Charts">
							<multiple-select
								:options="chartOptions"
								:selected.sync="computeChartSelected"
							/>
						</b-form-group>
					</div>

					<div class="col-xl-5">
						<b-form-group label="Chart per row">
							<b-form-select
								v-model="computeNoOfRowSelected"
								:options="noOfRowOptions"
							/>
						</b-form-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MultipleSelect from "@common-components/multiple-select/MultipleSelect.vue";
	import { BFormGroup, BFormSelect } from "bootstrap-vue";
	import { computed } from "@vue/composition-api";

	export default {
		props: {
			chartSelected: {
				type: Array,
				required: true,
			},
			chartOptions: {
				type: Array,
				required: true,
			},

			noOfRowSelected: {
				type: Number,
				required: true,
			},
			noOfRowOptions: {
				type: Array,
				required: true,
			},
		},
		components: {
			BFormGroup,
			BFormSelect,
			MultipleSelect,
		},
		setup(props, ctx) {
			const computeChartSelected = computed({
				get: () => props.chartSelected,
				set: (val) => {
					ctx.emit("update:chartSelected", val);
				},
			});

			const computeNoOfRowSelected = computed({
				get: () => props.noOfRowSelected,
				set: (val) => {
					ctx.emit("update:noOfRowSelected", val);
				},
			});

			return {
				computeChartSelected,
				computeNoOfRowSelected
			};
		},
	};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>